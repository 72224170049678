window.angular.module('MyHippoProducer.Components').directive('rewardCard', function () {
    return {
        restrict: 'E',
        scope: {
            title: '<',
            text: '<',
            end: '<',
            leftDays: '<',
            duration: '<',
        },
        templateUrl: 'components/reward-card/reward-card.html',
        controller: function ($scope, $interval) {
            $scope.showDate = false;
            $scope.counter = 0;
            $scope.showTime = true;
            $scope.calculatedLeftDays = 0;
            const calculateTime = function () {
                const now = new Date();
                if ($scope.leftDays > 0) {
                    $scope.showDate = true;
                    // If less than or equal to 1/4th time remains, styling will be red
                    $scope.redText = $scope.leftDays <= Math.ceil($scope.duration * 0.25);
                    $scope.calculatedLeftDays = $scope.leftDays + 1;
                } else if ($scope.leftDays === 0) {
                    $scope.counter = moment($scope.end, ['MM/DD/YYYY HH:mm:ss']).diff(now, 'seconds');
                    $scope.showDate = false;
                } else {
                    $scope.counter = 0;
                    $scope.showDate = false;
                }
            };
            calculateTime();
            const timer = $interval(function () {
                $scope.counter--;
            }, 1000
            );
            if ($scope.counter <= 0) {
                $interval.cancel(timer);
                $scope.counter = 0;
                $scope.showTime = false;
            }
        }
    };
});

window.angular.module('MyHippoProducer.Components').filter('secondsToDateTime', function () {
    return function (seconds) {
        if(seconds <= 0) {
            return null;
        }
        const hours = moment.duration(seconds, 'seconds').hours();
        const minutes = moment.duration(seconds, 'seconds').minutes();
        const formatedMinute = minutes < 10 ? `0${minutes}` : `${minutes}`;
        return `${hours}:${formatedMinute}`;
    };
});
